import React from "react"

import Layout from "../components/layout/layout"
import { Presentations } from "../components/presentations/presentations"

const IndexPage = () => (
  <Layout title="Presentations">
    <Presentations />
  </Layout>
)

export default IndexPage
