import React from "react"
import { StaticQuery, graphql } from "gatsby"

import styles from "./presentations.module.scss"

const getResourceDownloadUrl = (resource) => {
  if (resource.url) {
    return resource.url
  }

  if (resource.fileName) {
    return `https://us-central1-dragonia-org-pl.cloudfunctions.net/assets-download?file=${resource.fileName}`
  }

  return resource.file ? resource.file.url : ""
}

export const Presentations = () => (
  <StaticQuery
    query={graphql`
      {
        graphCMSData {
          presentations(orderBy: date_DESC) {
            id
            title
            date
            description
            country
            city
            eventName
            eventUrl
            resources {
              id
              name
              url
              file {
                url
              }
              fileName
            }
          }
        }
      }
    `}
    render={(data) => (
      <main>
        <article>
          <div>
            <div>
              <div>
                <div className={styles.roundedIcon}>
                  <i className="btn-icon fa fa-graduation-cap"></i>
                </div>

                <div className={styles.out}>
                  {data.graphCMSData.presentations.map((presentation) => (
                    <div className={styles.info} key={presentation.id}>
                      <h2 className={styles.infoTitle}>{presentation.title}</h2>
                      <span>
                        <a
                          href={presentation.eventUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {presentation.eventName}
                        </a>
                      </span>
                      <span className={styles.infoDate}>
                        {new Date(presentation.date).toLocaleDateString(
                          "en-US",
                          { year: "numeric", month: "long", day: "numeric" }
                        ) + ", "}
                        {presentation.country}, {presentation.city}
                      </span>
                      <p className={styles.infoDescription}>
                        {presentation.description}
                      </p>
                      <p className={styles.infoResources}>
                        <span>resources: </span>
                        {presentation.resources.map((resource, index) => (
                          <a
                            key={resource.id}
                            href={getResourceDownloadUrl(resource)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {resource.name +
                              (index < presentation.resources.length - 1
                                ? ","
                                : "")}
                          </a>
                        ))}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </article>
      </main>
    )}
  />
)
